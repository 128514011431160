import { createRouter, createWebHistory } from 'vue-router'
import Sidebar from '@/components/Sidebar.vue'
import Logo from '@/components/responsive/logo.vue'
import Login from '@/views/Login'
import Home from '@/views/Home'
import UserIndex from '@/views/User/Index'
import LesiIndex from '@/views/Lesi/Index'
import PartnerIndex from '@/views/Partner/Index'

const routes = [
  {
    path: '/:catchAll(.*)',
    redirect: '/'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      title: '登入'
    }
  },
  {
    path: '/',
    name: 'Home',
    components: {
      default: Home,
      sidebar: Sidebar,
      logo: Logo
    },
    meta: {
      title: '首頁'
    }
  },
  {
    path: '/user',
    redirect: { name: 'userList' },
    name: 'user',
    components: {
      default: UserIndex,
      sidebar: Sidebar,
      logo: Logo
    },
    meta: {
      title: '客戶管理'
    },
    children: [
      {
        path: 'list',
        name: 'userList',
        component: () => import('@/views/User/List'),
        meta: {
          title: '客戶列表'
        }
      },
      {
        path: 'add',
        name: 'userAdd',
        component: () => import('@/views/User/Add'),
        meta: {
          title: '新增客戶'
        }
      },
      {
        path: ':id',
        name: 'userSingleList',
        component: () => import('@/views/User/Single'),
        meta: {
          title: '客戶資訊'
        }
      },
      {
        path: 'edit/:id',
        name: 'userEdit',
        component: () => import('@/views/User/Edit'),
        meta: {
          title: '編輯客戶'
        }
      }
    ]
  },
  {
    path: '/lesi',
    redirect: { name: 'lesiList' },
    name: 'lesi',
    components: {
      default: LesiIndex,
      sidebar: Sidebar,
      logo: Logo
    },
    meta: {
      title: 'LESI 管理'
    },
    children: [
      {
        path: 'list',
        name: 'lesiList',
        component: () => import('@/views/Lesi/List'),
        meta: {
          title: 'LESI 列表'
        }
      },
      {
        path: 'add',
        name: 'lesiAdd',
        component: () => import('@/views/Lesi/Add'),
        meta: {
          title: '新增 LESI'
        }
      },
      {
        path: ':id',
        name: 'lesiSingleList',
        component: () => import('@/views/Lesi/Single'),
        meta: {
          title: 'LESI 資訊'
        }
      },
      {
        path: 'edit/:id',
        name: 'lesiEdit',
        component: () => import('@/views/Lesi/Edit'),
        meta: {
          title: '編輯 LESI'
        }
      }
    ]
  },
  {
    path: '/partner',
    redirect: { name: 'partnerList' },
    name: 'partner',
    components: {
      default: PartnerIndex,
      sidebar: Sidebar,
      logo: Logo
    },
    meta: {
      title: '夥伴管理'
    },
    children: [
      {
        path: 'list',
        name: 'partnerList',
        component: () => import('@/views/Partner/List'),
        meta: {
          title: '夥伴列表'
        }
      },
      {
        path: 'add',
        name: 'partnerAdd',
        component: () => import('@/views/Partner/Add'),
        meta: {
          title: '新增夥伴'
        }
      },
      {
        path: ':id',
        name: 'partnerSingleList',
        component: () => import('@/views/Partner/Single'),
        meta: {
          title: '夥伴資訊'
        }
      },
      {
        path: 'edit/:id',
        name: 'partnerEdit',
        component: () => import('@/views/Partner/Edit'),
        meta: {
          title: '編輯夥伴'
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // const getToken = localStorage.getItem('token')
  const getToken = localStorage.getItem('TOKEN_KEY')
  if (getToken) {
    next()
  } else {
    if (to.path !== '/login') next('/login')
    else next()
  }
})

export default router
