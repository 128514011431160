<template>
<div :class="tailwind">
  <div class="w-full h-full p-3 bg-neutral-800">
    <div class="flex items-center justify-between">
      <h3 class="title">{{ title }}</h3>
      <button @click="update()" :disabled="isUpdate" class="btn-in-bg py-[2px]"><cached class="mr-1" />更新</button>
    </div>
    <div class="relative">
      <Loading :isLoading="isLoading" :fixed="false" />
      <div class="my-8 sm:grid sm:grid-cols-3 sm:text-center sm:divide-x divide-neutral-700">
        <div v-for="item in items.slice(0,3)" :key="item" class="flex items-center text-center sm:block">
          <circle-slice class="mr-2 text-2xl sm:text-xl sm:mr-0" :class="'text-' + item.icon + '-500'" />
          <div class="order-last my-3 text-4xl text-white sm:text-5xl font-amount sm:order-none hover:text-default-orange"><router-link :to="`/lesi/list?lights=${item.icon}`">{{ item.amount }}</router-link></div>
          <div class="mr-2 text-3xl sm:text-base sm:mr-0" v-html="item.title"></div>
        </div>
      </div>
    </div>
    <div class="text-sm text-right text-neutral-500">
      <p>LESI 總數：{{ itemsAmount }} / {{ lesiAmount }}</p>
      <p>最後更新時間：{{ timeStamp(items[3].updateTime, 'full') }}</p>
    </div>
  </div>
</div>
</template>

<script>
import { ref, reactive, onMounted, watchEffect } from 'vue'
import { useStore } from 'vuex'
import { timeStamp } from '@/utility/timeStamp.js'
import circleSlice from 'vue-material-design-icons/CircleSlice8.vue'
import cached from 'vue-material-design-icons/Cached.vue'

export default {
  components: { circleSlice, cached },
  props: ['tailwind', 'title'],
  setup () {
    const store = useStore()
    const isLoading = ref(true)
    const isUpdate = ref(true)
    const nowDate = Math.floor(new Date().getTime() / 1000)
    const items = reactive([
      {
        icon: 'green',
        amount: store.state.lesiLights.green.length === 0 ? 0 : store.state.lesiLights.green.length,
        title: '3 分鐘內<br />連線正常'
      },
      {
        icon: 'red',
        amount: store.state.lesiLights.red.length === 0 ? 0 : store.state.lesiLights.red.length,
        title: '斷線 > 3 分鐘'
      },
      {
        icon: 'grey',
        amount: store.state.lesiLights.grey.length === 0 ? 0 : store.state.lesiLights.grey.length,
        title: '無連線'
      },
      {
        updateTime: store.state.lesiLights.updateTime === '' ? nowDate : store.state.lesiLights.updateTime
      }
    ])
    const lesiAmount = ref(0)
    const itemsAmount = ref(0)
    const getLesiStatus = (data) => {
      lesiAmount.value = store.state.lesiList.length
      items[0].amount = data.red.length
      items[1].amount = data.green.length
      items[2].amount = data.grey.length
      itemsAmount.value = items[0].amount + items[1].amount + items[2].amount
      if (itemsAmount.value === lesiAmount.value && lesiAmount.value > 0) {
        const nowDate = Math.floor(new Date().getTime() / 1000)
        items[3].updateTime = nowDate
        store.commit('setUpdateTime', nowDate)
        setTimeout(() => {
          isUpdate.value = false
          isLoading.value = false
        }, 800)
      }
    }
    const update = () => {
      isUpdate.value = true
      isLoading.value = true
      store.commit('clearLesiLights')
      store.state.lesiList.map((item) => {
        store.dispatch('getLesiTimeStamp', { type: 'lights', item })
      })
      watchEffect(() => {
        const data = store.state.lesiLights
        if (data === null) return
        getLesiStatus(data)
      })
    }
    onMounted(() => {
      watchEffect(() => {
        const data = store.state.lesiLights
        if (data === null) return
        getLesiStatus(data)
      })
    })
    return { isLoading, isUpdate, timeStamp, items, itemsAmount, lesiAmount, update }
  }
}
</script>
