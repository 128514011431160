export default {
  // Lesi 管理
  lesi: {
    list: 'list/lesis',
    detail: 'detail/lesi',
    timestamp: 'execute/getlasttimestamp',
    bind: 'execute/bindSubuser',
    unbind: 'execute/unbind',
    chart: 'execute/checkLesiAlive',
    location: 'list/lesiLocations/1/10',
    search: 'list/lesisSearch',
    system: 'execute/checkLesiSystem',
    sensors: 'execute/checkLesiSensor',
    cache: 'execute/cacheTool',
    backDoor: 'execute/putIotJob'
  },
  // 夥伴管理
  partner: {
    list: 'list/partners',
    detail: 'detail/partner'
  },
  // 客戶管理
  user: {
    list: 'list/users',
    detail: 'detail/user',
    // - 更新 sensor 點數 /v2/cognito/{cognito}/update 
    sensor: 'cognito'
  },
  // 資料 CURD (LESI / 夥伴 / 客戶)
  update: 'update',
  create: {
    lesi: 'create/lesi',
    partner: 'create/partner',
    user: 'create/user'
  },
  delete: {
    lesi: 'delete/lesi',
    partner: 'delete/partner',
    user: 'delete/user'
  },
  option: {
    partner: 'execute/getOptions/partner',
    user: 'execute/getOptions/user'
  },
  // login: 'execute/checkLogin'
  login: 'auth/login',
  jwt: 'jwt2'
}
