<template>
<div class="pb-3 mt-16 mb-3 border-b border-neutral-700">
  <ol class="flex">
    <li v-for="(item, i) in breadcrumbList" :key="i" class="pr-2">
      <router-link :to="item.path">{{ item.meta.title }}</router-link>
    </li>
    <li v-if="filterPayload.type === 'partner' && type">所屬夥伴「{{ type }}」</li>
    <li v-else-if="filterPayload.type === 'user' && type">所屬客戶「{{ type }}」</li>
    <li v-if="color">{{ lights(color) }}</li>
    <li v-if="thingAlias !== ''">{{ thingAlias }}</li>
  </ol>
</div>
</template>

<script>
import { inject, ref, reactive, watch, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'Breadcrumb',
  setup () {
    const api = inject('api')
    const $http = inject('http')
    const route = useRoute()
    const id = route.params.id
    const thingAlias = ref('')
    const color = route.query.lights
    const lights = computed(() => {
      return function (val) {
        switch (val) {
          case 'allL':
            return ''
          case 'green':
            return '3 分鐘內連線正常'
          case 'red':
            return '斷線 > 3 分鐘'
          case 'grey':
            return '無連線'
        }
      }
    })
    const filterPayload = reactive({
      type: null,
      id: null
    })
    const type = ref()
    const breadcrumbList = ref([])
    const isHome = () => {
      return route.name === 'Home'
    }
    const getBreadcrumbs = () => {
      let matched = route.matched
      if (!isHome(matched[0])) {
        matched = [{ path: '/', meta: { title: '首頁' } }].concat(matched)
      }
      matched.filter((el) => {
        if (el.path.indexOf(':id') > -1 || el.path.indexOf('undefined') > -1) {
          el.path = route.fullPath
        }
      })
      breadcrumbList.value = matched
    }
    const getLesiData = () => {
      const json = JSON.stringify({
        filter: filterPayload.type,
        id: filterPayload.id
      })
      $http.post(`/${api.lesi.list}/1/12`, json).then((res) => {
        switch (filterPayload.type) {
          case 'partner':
            type.value = res.data.result.lesis[0].partner_name
            break
          case 'user':
            type.value = res.data.result.lesis[0].user_company_name
            break
        }
      })
    }
    const getSingleAlias = () => {
      $http.post(`/${api.lesi.detail}/${id}`).then(res => {
        thingAlias.value = res.data.result.lesi[0].thing_alias
      })
    }

    watch(route, () => {
      getBreadcrumbs()
    })

    onMounted(() => {
      getBreadcrumbs()
      if (route.path.includes('lesi') && id) getSingleAlias()
      if (route.query.partner || route.query.user) {
        filterPayload.type = route.query.partner ? 'partner' : 'user'
        filterPayload.id = route.query.partner ? route.query.partner : route.query.user
        getLesiData()
      }
    })

    return { id, thingAlias, breadcrumbList, getBreadcrumbs, filterPayload, type, color, lights }
  }
}
</script>

<style scoped>
li::after {
  content: '/';
  display: inline-block;
  padding-left: .5rem;
}
li:last-child::after {
  content: '';
}
</style>
