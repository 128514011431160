<template>
  <div class="fixed inset-0 z-30 w-56 transition-all duration-300 bg-neutral-800 -left-64 sm:left-0 sm:w-56">
    <div class="flex justify-center invisible pt-5 mb-8 sm:visible">
      <router-link to="/"><img src="../assets/logo_white.svg" class="w-48 bg-neutral-800"></router-link>
    </div>
    <div class="flex items-center justify-between px-5 pb-3 border-b border-neutral-700">
      <div>
        <div class="text-2xl text-white">{{ nickname }}</div>
        <div class="text-neutral-300">{{ officename }}</div>
      </div>
      <div>
        <button @click="onLogout" class="btn-in-bg">登出</button>
      </div>
    </div>
    <ol>
      <li v-for="items in items" :key="items">
        <router-link :to="items.to" class="flex items-center py-4 pl-5 transition duration-300 hover:bg-[#211F1D] hover:text-default-orange">
          <component :is="items.icon" class="text-2xl"></component>
          <span class="inline-block pl-6">{{ items.title }}</span>
        </router-link>
      </li>
    </ol>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import WindowClosed from 'vue-material-design-icons/WindowClosed.vue'
import AccountCircle from 'vue-material-design-icons/AccountCircle.vue'
import AccountMultiple from 'vue-material-design-icons/AccountMultiple.vue'

export default {
  name: 'Sidebar',
  setup () {
    const router = useRouter()
    const nickname = localStorage.getItem('nickname')
    const officename = localStorage.getItem('officeName')

    const items = [
      {
        to: '/lesi',
        icon: 'window-closed',
        title: 'LESI 管理'
      },
      {
        to: '/partner',
        icon: 'account-multiple',
        title: '夥伴管理'
      },
      {
        to: '/user',
        icon: 'account-circle',
        title: '客戶管理'
      }
    ]
    
    const onLogout = () => {
      localStorage.removeItem('TOKEN_KEY')
      localStorage.removeItem('REFRESH_TOKEN_KEY')
      localStorage.removeItem('UID')
      localStorage.removeItem('officeName')
      localStorage.removeItem('nickname')
      router.push({ name: 'Login' })
    }
    return { nickname, officename, items, onLogout }
  },
  components: { WindowClosed, AccountCircle, AccountMultiple }
}
</script>

<style lang="postcss" scoped>
.router-link-active {
  @apply bg-[#211F1D] text-default-orange
}
</style>
