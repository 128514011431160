import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// ======= Global Components =======
import Loading from '@/components/Loading.vue'
// ======= API ========
import http from './axios/http'
import api from './axios/api'
import store from './store'
// ======= Style =======
import './assets/tailwind.css'
import 'vue-material-design-icons/styles.css'
// ======= Plugins =======
import { VueReCaptcha } from 'vue-recaptcha-v3'
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import Confirm from './components/confirm/index.js'

// import ECharts modules manually to reduce bundle size
import { CanvasRenderer } from 'echarts/renderers'
import { PieChart, BarChart, LineChart } from 'echarts/charts'
import { TitleComponent, LegendComponent, PolarComponent, GridComponent } from 'echarts/components'
import 'echarts/lib/component/tooltip'

use([
  CanvasRenderer,
  PieChart,
  BarChart,
  LineChart,
  TitleComponent,
  LegendComponent,
  PolarComponent,
  GridComponent
])

export const app = createApp(App)

app.use(VueReCaptcha, { siteKey: '6Le6kzggAAAAAN7aH3-lyJdjoiAKR2AapQW3k27h' })
app.use(store)
app.use(router)
app.use(Confirm)
app.provide('http', http)
app.provide('api', api)
app.component('Loading', Loading)
app.component('v-chart', ECharts)
app.component('v-select', vSelect)
app.mount('#app')
