<template>
<div :class="tailwind">
  <div class="w-full h-full p-3 bg-neutral-800">
    <div class="flex items-center justify-between">
      <h3 class="title">{{ title }}</h3>
      <button @click="update()" :disabled="isUpdate" class="btn-in-bg py-[2px]"><cached class="mr-1" />更新</button>
    </div>
    <div class="relative">
      <Loading :isLoading="isLoading" :fixed="false" />
      <div class="absolute w-full sm:relative sm:w-auto top-[4.5rem]">
        <div class="w-2/3 line"></div>
        <div class="w-1/3 opacity-50 line"></div>
      </div>
      <div class="grid w-full mt-12 sm:grid-cols-3">
        <div v-for="(value, index) in heading.value" :key="value">
          <bubble :title="heading.title[index]" circle :red="heading.title[index] === '7 天'" />
          <ol class="ml-5 mb-10 sm:mb-0 sm:ml-[75px] sm:-mt-4 list-decimal">
            <li v-for="(item, index) in lesiDays[value]" :key="item" :class="{ 'hidden': index > 7 && hide[value] === true }">
              <router-link :to="`/lesi/${item.id}`" class="hover:text-default-orange">
                <span v-if="item.thing_alias !== null">{{ item.thing_alias }}</span>
                <span v-else>{{ item.thing_name }}</span>
              </router-link>
                <span v-if="item.user_company_name === null">(公司未登打)</span>
                <span v-else>(<router-link :to="`/user/${item.user_id}`" class="hover:text-default-orange">{{ item.user_company_name }}</router-link>)</span>
            </li>
            <button v-show="lesiDays[value].length > 9" @click="hide[value] = !hide[value]" class="btn-in-bg btn-resize">
              <span v-if="hide[value] === true">更多</span>
              <span v-else>收合</span>
            </button>
          </ol>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { ref, reactive, onMounted, watchEffect } from 'vue'
import { useStore } from 'vuex'
import bubble from '@/components/Bubble.vue'
import cached from 'vue-material-design-icons/Cached.vue'

export default {
  components: { bubble, cached },
  props: ['tailwind', 'title'],
  setup () {
    const store = useStore()
    const isLoading = ref(true)
    const isUpdate = ref(true)
    const lesiDays = ref({
      zero: [],
      one: [],
      seven: [],
      ten: []
    })
    const heading = {
      title: ['1 天', '7 天', '10 天'],
      value: ['one', 'seven', 'ten']
    }
    const hide = reactive({
      one: true,
      seven: true,
      ten: true
    })
    const lesiAmount = ref(0)
    const itemsAmount = ref(0)
    const getLesiDays = () => {
      lesiDays.value = store.state.lesiDays
      lesiAmount.value = store.state.lesiList.length
      itemsAmount.value = lesiDays.value.zero.length + lesiDays.value.one.length + lesiDays.value.seven.length + lesiDays.value.ten.length
      if (itemsAmount.value === lesiAmount.value && lesiAmount.value > 0) {
        setTimeout(() => {
          isUpdate.value = false
          isLoading.value = false
        }, 800)
      }
    }
    const update = () => {
      isUpdate.value = true
      isLoading.value = true
      store.commit('clearLesiDays')
      store.state.lesiList.map(item => {
        store.dispatch('getLesiTimeStamp', { type: 'days', item })
      })
      watchEffect(() => {
        const data = store.state.lesiDays
        if (data === null) return
        getLesiDays(data)
      })
    }
    onMounted(() => {
      watchEffect(() => {
        const data = store.state.lesiDays
        if (data === null) return
        getLesiDays(data)
      })
    })
    return { isLoading, isUpdate, lesiDays, heading, hide, update }
  }
}
</script>

<style lang="postcss">
.line {
  @apply hidden w-2 border-default-orange
}
@media only screen and (min-width: 640px) {
  .line {
    @apply inline-block h-1 border-l-0 border-t-2 !important
  }
}
</style>

<style lang="postcss" scoped>
a { @apply text-neutral-100 }
.btn-resize { @apply py-[2px] my-5 }
</style>
