<template>
  <Loading :isLoading="isLoading" :fixed="true" />
  <div class="flex items-center justify-center w-full min-h-screen mx-auto bg-neutral-900 text-neutral-400">
    <form @submit.prevent="onSubmit" class="flex w-[30rem] flex-col space-y-10">
      <div class="mx-auto">
        <img src="../assets/logo_white.svg" class="w-48 bg-neutral-900" />
      </div>
      <div class="relative mb-5">
        <input v-model="account" type="text" class="w-full input" placeholder=" " autocomplete="username" />
        <label class="input__label">登入帳號</label>
      </div>
      <div class="relative mb-5">
        <input v-model="password" type="password" name="password" class="w-full input" placeholder=" " autocomplete="current-password" />
        <label class="input__label">登入密碼</label>
      </div>
      <button class="rounded-none btn-in-bg">登入</button>
      <div class="h-4 text-center text-red-500">{{ alertMessage }}</div>
      <div class="text-center text-neutral-700">{{ version }}</div>
    </form>
  </div>
</template>

<script>
import { inject, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useReCaptcha } from 'vue-recaptcha-v3'
import axios from 'axios'

export default {
  setup () {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha()
    const api = inject('api')
    const $http = inject('http')
    const router = useRouter()
    const isLoading = ref(false)
    const account = ref('')
    const password = ref('')
    const alertMessage = ref('')
    const version = ref('')
    const checkLogin = () => {
      const json = JSON.stringify({
        account: account.value,
        password: password.value
      })
      $http.post(`${api.login}`, json).then(res => {
        if (res.data.status === 'success') {
          localStorage.setItem('TOKEN_KEY', res.data.token.accessToken)
          localStorage.setItem('REFRESH_TOKEN_KEY', res.data.token.refreshToken)
          localStorage.setItem('UID', res.data.account.uid)
          localStorage.setItem('ID', res.data.account.id)
          localStorage.setItem('nickname', res.data.account.nick_name)
          localStorage.setItem('officeName', res.data.account.office_name)
          isLoading.value = false
          router.push({ name: 'Home' })
        }
      }).catch(() => {
        isLoading.value = false
        alertMessage.value = '帳號或密碼錯誤'
      })
    }
    const onSubmit = async () => {
      isLoading.value = true
      await recaptchaLoaded()
      const googleToken = await executeRecaptcha('login')
      if (account.value === '' || password.value === '') {
        isLoading.value = false
        alertMessage.value = '請輸入帳號或密碼'
      } else {
        if (googleToken) checkLogin()
        else {
          isLoading.value = false
          alertMessage.value = 'Google 判定您為機器人，如需線上解鎖請聯絡管理員'
        }
      }
    }
    onMounted(() => {
      axios.get('https://gitlab.com/api/v4/projects/33535106/releases', { headers: { 'PRIVATE-TOKEN': 'glpat-6tgHcka-NsmqTWxCu9vD' } }).then((res) => {
        version.value = res.data[0].name
      })
    })
    return { account, password, alertMessage, version, onSubmit, isLoading }
  }
}
</script>

<style scoped>
.login {
  margin-left: 0;
}
</style>
