<template>
  <div :class="tailwind">
    <div
      class="relative w-full h-full p-3 pb-0 bg-neutral-800"
      style="overflow: hidden"
    >
      <div class="flex items-center justify-between">
        <h3 class="title">{{ title }}</h3>
      </div>
      <Loading :isLoading="isLoading" :fixed="false" />
      <v-chart
        class="chart"
        :option="option.data"
        style="height: 100%; width: 100%; padding: 0 0 5% 0; z-index: 9"
      />
    </div>
  </div>
</template>

<script>
import { ref, reactive, inject, onMounted, watchEffect } from 'vue'
import { useStore } from 'vuex'
export default {
  props: ['tailwind', 'title'],
  setup () {
    const date = new Date()
    const store = useStore()
    const maxMonth = date.getMonth() + 1
    const maxYear = date.getFullYear()
    const maxI = 6
    const nowTimestamp = Math.floor(new Date().getTime() / 1000)
    const showMonth = []
    const showSensorCounts =
      store.state.sensorCounts.length === 0 ? [] : store.state.sensorCounts
    const showLesiCounts =
      store.state.lesiCounts.length === 0 ? [] : store.state.lesiCounts
    const limitDate = []
    const idxSensorCounts = []
    const idxLesiCounts = []
    const api = inject('api')
    const $http = inject('http')
    const isLoading = ref(true)
    const option = reactive({
      data: null
    })
    const checkSensorCounts = () => {
      const idx = idxSensorCounts.length
      const idx2 = idxLesiCounts.length
      if (idx === maxI && idx2 === maxI) {
        store.commit('setSensorCounts', showSensorCounts)
        store.commit('setLesiCounts', showLesiCounts)
        runOption()
      } else {
        setTimeout(() => {
          checkSensorCounts()
        }, 5000)
      }
    }
    const runOption = () => {
      const idx = showSensorCounts.length
      const idx2 = showSensorCounts.length
      if (idx === maxI && idx2 === maxI) {
        setTimeout(() => {
          isLoading.value = false
        }, 300)
      } else {
        isLoading.value = true
      }

      option.data = {
        tooltip: {
          trigger: 'axis'
        },
        xAxis: [
          {
            type: 'category',
            data: showMonth
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: 'Sensor數量',
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: '#65676f'
              }
            },
            min: 0
          },
          {
            type: 'value',
            name: 'LESI數量',
            alignTicks: true,
            position: 'right',
            axisLine: {
              show: true,
              lineStyle: {
                color: '#ff8300'
              }
            },
            min: 0
          }
        ],
        grid: {
          top: '30',
          left: '40',
          right: '40',
          bottom: '10',
          height: '82%',
          width: '80%'
        },
        series: [
          {
            name: 'Sensor數量',
            data: showSensorCounts,
            type: 'bar',
            barWidth: '50%',
            color: '#aaaaaa'
          },
          {
            name: 'LESI數量',
            type: 'line',
            data: showLesiCounts,
            yAxisIndex: 1,
            color: '#cf6a00'
          }
        ]
      }
    }
    const getSensors = () => {
      // 處理歷史sensor數量
      for (let j = 0; j < maxI; j++) {
        showSensorCounts[j] = 0
        const cacheJson = JSON.stringify({
          methond: 'get',
          type: 'sensorCount',
          key: limitDate[j],
          nowJ: j
        })
        const json = {
          start_time: limitDate[j],
          end_time: limitDate[j + 1],
          nowJ: j
        }
        $http.post(`/${api.lesi.cache}`, cacheJson).then((res) => {
          if (res.data.cache !== null) {
            showSensorCounts[res.data.postData.nowJ] = parseFloat(
              res.data.cache
            )
            idxSensorCounts.push(res.data.postData.nowJ)
          } else {
            store.state.lesiList.map((item, index, row) => {
              json.thing_name = item.thing_name
              const sjson = JSON.stringify(json)
              $http.post(`/${api.lesi.sensors}`, sjson).then((res) => {
                if (res.data.sensorCount > 0) {
                  showSensorCounts[res.data.postData.nowJ] +=
                    res.data.sensorCount
                }
                if (index + 1 === row.length) {
                  const inCacheJson = JSON.stringify({
                    methond: 'put',
                    type: 'sensorCount',
                    key: limitDate[res.data.postData.nowJ],
                    cache: showSensorCounts[res.data.postData.nowJ]
                  })
                  if (res.data.postData.nowJ < maxI - 1) {
                    $http
                      .post(
                        'https://api.goodlinker.io/lesiAdmin/execute/cacheTool',
                        inCacheJson
                      )
                      .then(() => {})
                  }
                  idxSensorCounts.push(res.data.postData.nowJ)
                }
              })
            })
          }
        })
      }
    }
    const getLesis = () => {
      // 處理歷史Lesi數量
      for (let k = 0; k < maxI; k++) {
        showLesiCounts[k] = 0
        const cacheJson = JSON.stringify({
          methond: 'get',
          type: 'lesiCount',
          key: limitDate[k],
          nowJ: k
        })
        const json = {
          start_time: limitDate[k],
          end_time: limitDate[k + 1],
          nowJ: k
        }
        $http.post(`/${api.lesi.cache}`, cacheJson).then((res) => {
          if (res.data.cache !== null) {
            showLesiCounts[res.data.postData.nowJ] = parseFloat(res.data.cache)
            idxLesiCounts.push(res.data.postData.nowJ)
          } else {
            store.state.lesiList.map((item2, index2, row2) => {
              json.thing_name = item2.thing_name
              const sjson = JSON.stringify(json)
              $http.post(`/${api.lesi.sensors}`, sjson).then((res) => {
                if (res.data.sensorCount > 0) {
                  showLesiCounts[res.data.postData.nowJ] += 1
                }
                if (index2 + 1 === row2.length) {
                  const inCacheJson = JSON.stringify({
                    methond: 'put',
                    type: 'lesiCount',
                    key: limitDate[res.data.postData.nowJ],
                    cache: showLesiCounts[res.data.postData.nowJ]
                  })
                  if (res.data.postData.nowJ < maxI - 1) {
                    $http
                      .post(
                        'https://api.goodlinker.io/lesiAdmin/execute/cacheTool',
                        inCacheJson
                      )
                      .then(() => {})
                  }
                  idxLesiCounts.push(res.data.postData.nowJ)
                }
              })
            })
          }
        })
        checkSensorCounts()
      }
    }
    // 處理六月日期
    const getLimitDate = () => {
      for (let i = maxI - 1; i >= 0; i--) {
        // showSensorCounts[i] = 0
        if (maxMonth - i > 0) {
          const tmp = maxMonth - i
          showMonth.push(tmp + '月')
          const nowdate = `${maxYear}-${tmp}-1`
          const nowtt = new Date(nowdate)
          limitDate.push(nowtt.getTime() / 1000)
        } else {
          const tmp = maxMonth - i + 12
          const ytmp = maxYear - 1
          showMonth.push(tmp + '月')
          const nowdate = `${ytmp}-${tmp}-1`
          const nowtt = new Date(nowdate)
          limitDate.push(nowtt.getTime() / 1000)
        }
      }
      limitDate.push(nowTimestamp)
    }
    onMounted(() => {
      // 如果有sensorCounts有暫存 直接畫出來
      getLimitDate()
      runOption()
      watchEffect(() => {
        if (
          store.state.lesiLights.updateTime !== '' &&
          store.state.systemData.length === 0
        ) {
          // 這邊這一行如果拿掉 當senser數量的cache有缺時 不會自動補上 留給Muki修修
          isLoading.value = true
          getSensors()
          getLesis()
        }
      })
    })
    return { option, isLoading }
  }
}
</script>
