<template>
  <div class="flex items-center mt-5 sm:invisible">
    <label for="menuToggle">
      <menu-open class="text-4xl text-default-orange hover:text-orange-300 mr-3" />
    </label>
    <router-link to="/">
      <img src="@/assets/logo_white.svg" class="w-48 bg-neutral-900">
    </router-link>
  </div>
</template>

<script>
import MenuOpen from 'vue-material-design-icons/MenuOpen.vue'

export default {
  components: { MenuOpen }
}
</script>

<style lang="css" scoped>
.material-design-icon::v-deep(.material-design-icon__svg) {
  bottom: 0;
}
</style>
