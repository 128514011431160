import { createStore } from 'vuex'
import $http from '@/axios/http'
import api from '@/axios/api'

export default createStore({
  state: {
    lesiList: [],
    lesiLights: {
      red: [],
      green: [],
      grey: [],
      updateTime: ''
    },
    systemData: [],
    sensorCounts: [],
    lesiCounts: [],
    lesiDays: {
      zero: [],
      one: [],
      seven: [],
      ten: []
    }
  },
  mutations: {
    setLesiList (state, value) {
      state.lesiList = value
    },
    setSystemData (state, value) {
      state.systemData = value
    },
    setSensorCounts (state, value) {
      state.sensorCounts = value
    },
    setLesiCounts (state, value) {
      state.lesiCounts = value
    },
    setLesiLights (state, { color, item }) {
      state.lesiLights[color].push(item)
    },
    setUpdateTime (state, value) {
      state.lesiLights.updateTime = value
    },
    setLesiDays (state, { day, item }) {
      state.lesiDays[day].push(item)
    },
    clearSystemData (state) {
      state.systemData.length = 0
    },
    clearLesiLights (state) {
      state.lesiLights.green.length = 0
      state.lesiLights.red.length = 0
      state.lesiLights.grey.length = 0
      state.lesiLights.updateTime = ''
    },
    clearLesiDays (state) {
      state.lesiDays.zero.length = 0
      state.lesiDays.one.length = 0
      state.lesiDays.seven.length = 0
      state.lesiDays.ten.length = 0
    }
  },
  actions: {
    getLesiTimeStamp (context, { type, item }) {
      const nowDate = Math.floor(new Date().getTime() / 1000)
      $http.post(`/${api.lesi.timestamp}/${item.thing_name}`).then((res) => {
        const lights = nowDate - res.data
        const day = Math.floor((nowDate - res.data) / 86400)
        const switchLights = () => {
          switch (true) {
            case lights === nowDate:
              context.commit('setLesiLights', { color: 'grey', item })
              break
            case lights <= 180:
              context.commit('setLesiLights', { color: 'green', item })
              break
            case lights > 180:
              context.commit('setLesiLights', { color: 'red', item })
              break
          }
        }
        const switchDays = () => {
          switch (true) {
            case day >= 10:
              context.commit('setLesiDays', { day: 'ten', item })
              break
            case day >= 7:
              context.commit('setLesiDays', { day: 'seven', item })
              break
            case day >= 1:
              context.commit('setLesiDays', { day: 'one', item })
              break
            case day < 1:
              context.commit('setLesiDays', { day: 'zero', item })
              break
          }
        }
        if (type === 'lights') switchLights()
        else if (type === 'days') switchDays()
        else {
          switchLights()
          switchDays()
        }
      })
    }
  },
  getters: {
    // doneLesiDays: (state) => {
    //   return state.lesiDays
    // }
  },
  modules: {
  }
})
