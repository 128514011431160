<template>
  <div class="flex flex-1 ml-5 sm:ml-64 transition-all duration-300">
    <div class="w-full mr-6 pb-20">
      <logo />
      <Breadcrumb />
      <router-view />
    </div>
  </div>
</template>

<script>
import logo from '@/components/responsive/logo.vue'
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
  components: { logo, Breadcrumb }
}
</script>
