<template>
  <div class="flex flex-1 ml-5 transition-all duration-300 sm:ml-64">
    <div class="w-full pb-20 mr-6">
      <logo />
      <div class="pb-3 pl-1 mt-16 mb-3 border-b border-neutral-700">
        首頁
      </div>
      <div class="grid grid-cols-1 sm:grid-cols-4 sm:grid-flow-col sm:gap-4">
        <card-lesi-status title="LESI 連線狀態" tailwind="col-auto sm:row-span-1 xl:row-span-1 sm:col-span-2 xl:col-span-1 sm:col-start-1" />
        <card-chart title="LESI及感測器累計數量" tailwind="col-auto sm:row-span-1 xl:row-span-1 sm:col-span-2 xl:col-span-1 sm:col-start-3 xl:col-start-2"/>
        <card-lesi-map title="LESI 位置" tailwind="col-auto sm:row-span-2 xl:row-span-2 sm:col-span-4 xl:col-span-2 sm:col-start-1 xl:col-start-3 min-h-[743px]" />
        <card-cpu title="LESI 硬體狀態" tailwind="col-auto sm:row-span-1 xl:row-span-1 sm:col-span-4 xl:col-span-2  sm:col-start-1 min-h-[440px]" />
        <card-footer title="LESI 斷線天數" tailwind="col-auto sm-row-span-1 sm:col-span-4 sm:col-start-1" />
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, reactive, onMounted } from 'vue'
import { useStore } from 'vuex'
import logo from '@/components/responsive/logo.vue'
import cardLesiStatus from '@/components/home/CardLesiStatus.vue'
import cardCpu from '@/components/home/CardCPU.vue'
import cardChart from '@/components/home/CardChart.vue'
import cardLesiMap from '@/components/home/CardLesiMap.vue'
import cardFooter from '@/components/home/CardFooter.vue'

export default {
  setup () {
    const api = inject('api')
    const $http = inject('http')
    const store = useStore()
    const lesiAmount = ref(0)
    const lesiData = reactive({
      data: []
    })

    const getLesiAmount = () => {
      const json = JSON.stringify({
        filter: 'none'
      })
      $http.post(`/${api.lesi.list}/1/1`, json).then((res) => {
        lesiAmount.value = res.data.result.pages.total_page
      }).then(() => {
        $http.post(`/${api.lesi.list}/1/${lesiAmount.value}`, json).then((res) => {
          res.data.result.lesis.map((item) => {
            if (item.thing_name !== '') {
              lesiData.data.push(item)
            }
          })
          store.commit('setLesiList', lesiData.data)
        }).then(() => {
          getLesiTimeStamp()
        })
      })
    }
    const getLesiTimeStamp = () => {
      store.state.lesiList.map(item => {
        store.dispatch('getLesiTimeStamp', { type: 'all', item })
      })
    }

    onMounted(() => {
      if (store.state.lesiList.length === 0) getLesiAmount()      
    })
  },
  components: { logo, cardLesiStatus, cardCpu, cardChart, cardLesiMap, cardFooter }
}
</script>

<style lang="postcss" scoped>
:deep(.title) {
  @apply text-neutral-400 text-base;
}
</style>
