<template>
  <div :class="tailwind">
    <div class="w-full h-full p-3 bg-neutral-800">
      <div class="flex items-center justify-between">
        <h3 class="title">{{ title }}</h3>
        <button @click="update()" :disabled="isUpdate" class="btn-in-bg py-[2px]"><cached class="mr-1" />更新</button>
      </div>
      <div class="relative">
        <Loading :isLoading="isLoading" :fixed="false" />
        <table class="table-auto w-full mt-4 h-[319px]">
          <thead>
            <tr>
              <th v-for="item in tableTitle" :key="item" class="pb-2 text-left hover:cursor-pointer" :class="{'!font-bold': currentSort === item.id}" @click="sortDir(item.id)">
                {{ item.value }}
                <chevron-up class="text-default-orange text-xl top-[2px]" :class="{'!invisible': currentSort !== item.id, 'rotate-180 top-[6px]': currentSortDir == 'desc'}" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in sortData" :key="item">
              <td class="td" :data-title="tableTitle[0].value">
                <router-link :to="`/lesi/${item.id}`" class="link thingname--overflow">{{ item.thing_name }}</router-link>
                <p class="thingname--overflow">{{ item.thing_alias }}</p>
              </td>
              <td class="td" :data-title="tableTitle[1].value">{{ item.cpuUsage }} %</td>
              <td class="td" :data-title="tableTitle[2].value">{{ item.diskUsage }} %</td>
              <td class="td" :data-title="tableTitle[3].value">{{ item.memoryUsage }} %</td>
              <td class="td" :data-title="tableTitle[4].value">{{ item.socTemperature }} °C</td>
            </tr>
          </tbody>
        </table>
        <p>
          <ol class="flex flex-wrap justify-center mt-5">
            <li v-for="item in totalPage" :key="item">
              <div @click="currentPage = item" :class="{current: item === currentPage}" class="px-3 py-1 hover:cursor-pointer">{{ item }}</div>
            </li>
          </ol>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, reactive, onMounted, computed, watchEffect } from 'vue'
import { useStore } from 'vuex'
import ChevronUp from 'vue-material-design-icons/ChevronUp.vue'
import cached from 'vue-material-design-icons/Cached.vue'

export default {
  components: { ChevronUp, cached },
  props: ['tailwind', 'title'],
  setup () {
    const api = inject('api')
    const $http = inject('http')
    const store = useStore()
    const isLoading = ref(true)
    const isUpdate = ref(true)
    const tableTitle = [
      { id: 'thing_name', value: 'LESI 資料' },
      { id: 'cpuUsage', value: 'CPU 使用率' },
      { id: 'diskUsage', value: '硬碟使用率' },
      { id: 'memoryUsage', value: '記憶體使用率' },
      { id: 'socTemperature', value: '核心溫度' }
    ]
    const currentSort = ref('cpuUsage')
    const currentSortDir = ref('desc')
    const pageSize = 5
    const currentPage = ref(1)
    const systemData = store.state.systemData.length === 0 ? reactive([]) : store.state.systemData
    const sortData = computed(() => {
      return [...systemData].sort((a, b) => {
        let modifier = 1
        if (currentSortDir.value === 'desc') modifier = -1
        if (a[currentSort.value] < b[currentSort.value]) return -1 * modifier
        if (a[currentSort.value] > b[currentSort.value]) return 1 * modifier
        return 0
      }).filter((row, index) => {
        const start = (currentPage.value - 1) * pageSize
        const end = currentPage.value * pageSize
        if (index >= start && index < end) return true
      })
    })
    const sortDir = (value) => {
      if (value === currentSort.value) {
        currentSortDir.value = currentSortDir.value === 'asc' ? 'desc' : 'asc'
      } else {
        currentSort.value = value
      }
    }
    const getSystem = () => {
      store.state.lesiList.map((item, index, row) => {
        $http.post(`/${api.lesi.system}/${item.thing_name}`).then((res) => {
          if (res.data.system) {
            systemData.push(Object.assign(item, res.data.system))
          }
          if (index + 1 === row.length) {
            setTimeout(() => {
              isUpdate.value = false
              isLoading.value = false
            }, 300)
            store.commit('setSystemData', systemData)
          }
        })
      })
    }
    const totalPage = computed(() => {
      return Math.ceil(systemData.length / pageSize)
    })
    const update = () => {
      isUpdate.value = true
      isLoading.value = true
      store.commit('clearSystemData')
    }
    onMounted(() => {
      watchEffect(() => {
        if (store.state.lesiLights.updateTime !== '' && store.state.systemData.length === 0) getSystem()
      })
      if (store.state.systemData.length) {
        setTimeout(() => {
          isUpdate.value = false
          isLoading.value = false
        }, 300)
      }
    })
    return { isLoading, isUpdate, tableTitle, sortData, systemData, sortDir, currentSort, currentSortDir, pageSize, currentPage, totalPage, update }
  }
}
</script>

<style lang="postcss" scoped>
.td {
  @apply border-neutral-600 md:py-1
}
.current {
  @apply rounded-lg text-white bg-default-orange;
}
@media only screen and (min-width: 768px) and (max-width: 1440px) {
  .thingname--overflow {
    @apply text-ellipsis overflow-hidden w-[100px] whitespace-nowrap block
  }
}
</style>
