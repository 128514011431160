import axios from 'axios'
import { app } from '@/main.js'

let UID = localStorage.getItem('UID')

const $http = axios.create({
  baseURL: process.env.VUE_APP_API
})

const refreshToken = () => {
  const REFRESH_TOKEN_KEY = localStorage.getItem('REFRESH_TOKEN_KEY')
  return $http.post('/auth/refreshtoken', JSON.stringify({
    uid: UID,
    token: REFRESH_TOKEN_KEY
  }), true)
}

// 攔截請求
$http.interceptors.request.use(config => {
  const TOKEN_KEY = localStorage.getItem('TOKEN_KEY')
  UID = localStorage.getItem('UID')
  if (!config.url.includes('/login') && !config.url.includes('/getOptions')) {
    config.headers.token = `${TOKEN_KEY}`
    config.headers.uid = `${UID}`
  }
  return config
}, error => {
  return Promise.reject(error)
})

// 攔截回應
$http.interceptors.response.use(res => {
  switch (res.status) {
    case 200:
      return Promise.resolve(res)
    default:
      console.log(res.status)
  }
}, error => {
  if (error && error.response) {
    switch (error.response.status) {
      case 400:
        console.error(error.response)
        break
      case 401:
        console.error(error)
        break
      case 403:
        console.error(error)
        if (!error.config.url.includes('/auth/refreshtoken')) {
          const { config } = error
          return refreshToken().then(res => {
            localStorage.setItem('TOKEN_KEY', res.data.token.accessToken)
            return $http(config)
          }).catch(err => {
            console.log('error to login', err)
            localStorage.removeItem('TOKEN_KEY')
            localStorage.removeItem('REFRESH_TOKEN_KEY')
            localStorage.removeItem('UID')
            app.config.globalProperties.$confirm.show({
              content: '您的登入已失效，請重新登入。(Identity is Invalid, Please Login Again.)',
              showConfirmButton: false,
              cancelText: '確定',
              onCancel: () => { window.location.href = '/login' }
            })
            // return Promise.reject(err)
          })
        }
        break
      default:
        console.error(`攔截 ${error.response.status} error`, error.response)
        return Promise.reject(error)
    }
  }
})

export default $http
