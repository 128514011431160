<template>
  <div class="relative inline-block ml-0 text-center sm:-top-5 sm:ml-10">
    <div class="relative -top-3 bg-default-orange text-white text-sm rounded-md px-2 py-1 after:hidden sm:after:block after:content-[''] after:absolute after:left-[calc(50%-8px)] after:-bottom-3 after:border-default-orange after:border-8 after:border-b-transparent after:border-l-transparent after:border-r-transparent" :class="{ 'bg-red-500': red,  'after:border-red-500' : red }">
      {{ title }}
    </div>
    <div v-if="circle" class="hidden w-3 h-3 mt-0 -ml-6 rounded-full sm:block sm:mx-auto bg-default-orange"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    circle: Boolean,
    red: Boolean
  }
}
</script>
