<template>
  <div :class="tailwind">
    <div class="w-full h-full p-3 bg-neutral-800">
      <div class="flex items-center justify-between">
        <h3 class="title">{{ title }}</h3>
      </div>
      <div class="relative mt-2 h-4/5">
        <Loading :isLoading="isLoading" :fixed="false" />
        <div id="map"></div>
      </div>
      <div class="grid grid-cols-2 gap-5 mt-5">
        <template v-for="item in locationData.tags" :key="item">
        <div @click="setMarkers(item)" class="flex items-center justify-between w-full px-3 py-2 bg-neutral-900 hover:cursor-pointer hover:text-default-orange">
          <div><mapMarker class="text-default-orange" /> {{ item.show_name }}</div>
          <div class="text-4xl">{{ item.amount }}</div>
        </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref, reactive, onMounted } from 'vue'
import { Loader } from '@googlemaps/js-api-loader'
import { MarkerClusterer } from '@googlemaps/markerclusterer'
import mapMarker from 'vue-material-design-icons/MapMarker.vue'

export default {
  components: { mapMarker },
  props: ['tailwind', 'title'],
  setup () {
    const api = inject('api')
    const $http = inject('http')
    const isLoading = ref(true)
    const states = reactive({
      google: null,
      map: null
    })
    const locationData = reactive({
      tags: [],
      location: []
    })
    const getAxios = async () => {
      await $http.post(`/${api.lesi.location}`).then((res) => {
        locationData.location = res.data.result.lesiLocations.filter((obj) => {
          return obj.lat !== null && obj.lng !== null
        })
        locationData.tags = res.data.result.tags
        setTimeout(() => {
          isLoading.value = false
        }, 800)
      })
    }
    const initMap = async () => {
      const loader = new Loader({
        apiKey: 'AIzaSyAKANKuqSSloZIS66E-VkPEL1Sg01qh53E',
        version: 'weekly',
        libraries: ['places'],
        language: 'zh-TW'
      })
      states.google = await loader.load()
      states.map = new states.google.maps.Map(document.getElementById('map'), {
        zoom: 7,
        center: { lat: 23.834989265038452, lng: 120.9671180528529 },
        mapTypeControl: false,
        fullscreenControl: false,
        styles: [
          {
            featureType: 'poi.business',
            stylers: [{
              visibility: 'off'
            }]
          },
          {
            featureType: 'transit',
            elementType: 'labels.icon',
            stylers: [{ visibility: 'off' }]
          }
        ]
      })
      const infoWindow = new states.google.maps.InfoWindow({
        content: '',
        disableAutoPan: true
      })
      const markers = locationData.location.map((i) => {
        const marker = new states.google.maps.Marker({
          position: { lat: i.lat, lng: i.lng },
          icon: {
            // url: 'https://cdn-icons-png.flaticon.com/512/684/684908.png',
            url: './sign.png',
            size: new states.google.maps.Size(30, 30),
            scaledSize: new states.google.maps.Size(30, 30),
            labelOrigin: new states.google.maps.Point(15, 40)
          }
        })

        marker.addListener('click', () => {
          infoWindow.setContent(`
            <div class="text-black font-normal text-[15px] mb-2">LESI 資訊</div>
            <div class="font-normal text-black">${i.user_company_name}</div>
            <div class="font-normal text-neutral-700">${i.thing_name}</div>
            <div class="font-normal text-neutral-700">${i.thing_alias}</div>
            <div class="mt-2"><a class="font-normal text-default-orange hover:text-neutral-600" href="/lesi/${i.id}" target="_blank">LESI 詳細資訊</a></div>
          `)
          infoWindow.open(states.map, marker)
        })

        states.map.addListener('zoom_changed', () => {
          const zoom = states.map.getZoom()
          if (zoom > 11) {
            const companyName = String(i.user_company_name)
            marker.setLabel({
              text: companyName,
              color: '#ff8300',
              fontSize: '14px',
              className: 'map-label'
            })
          } else {
            marker.setLabel('')
          }
        })
        return marker
      })
      const color = '#0000ff'
      const svg = window.btoa(`
        <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
          <circle cx="120" cy="120" opacity=".6" r="70" />
          <circle cx="120" cy="120" opacity=".3" r="90" />
          <circle cx="120" cy="120" opacity=".2" r="110" />
        </svg>`
      )
      const renderer = {
        render: ({ count, position }) =>
          new states.google.maps.Marker({
            icon: {
              // url: './sign.png',
              url: `data:image/svg+xml;base64,${svg}`,
              scaledSize: new states.google.maps.Size(45, 45)
            },
            label: { text: String(count), color: 'white', fontSize: '12px' },
            position,
            zIndex: Number(states.google.maps.Marker.MAX_ZINDEX) + count
          })
      }
      const markerCluster = new MarkerClusterer({ markers, map: states.map, renderer })
      markerCluster.setMap(states.map)
    }

    const setMarkers = (item) => {
      states.map.panTo({ lat: item.lat, lng: item.lng })
      states.map.setZoom(item.zoom)
    }

    onMounted(async () => {
      await getAxios()
      await initMap()
    })
    return { isLoading, locationData, setMarkers }
  }
}
</script>

<style>
.map-label {
  background: #fff;
  display: inline-block;
  padding: 1px 3px;
}
</style>

<style scoped>
#map {
  width: 100%;
  height: 100%;
}
</style>

<style lang="postcss" scoped>
.active {
  @apply text-default-orange;
}
</style>
