const timeStamp = (val, format) => {
  const tmp = new Date(val * 1000)
  const year = tmp.getFullYear()
  let month = tmp.getMonth() + 1
  month = month < 10 ? `0${month}` : month
  let day = tmp.getDate()
  day = day < 10 ? `0${day}` : day
  let hour = tmp.getHours()
  hour = hour < 10 ? `0${hour}` : hour
  let min = tmp.getMinutes()
  min = min < 10 ? `0${min}` : min
  let sec = tmp.getSeconds()
  sec = sec < 10 ? `0${sec}` : sec
  const week = tmp.getDay()
  const weekList = ['日', '一', '二', '三', '四', '五', '六']
  if (format === 'full') {
    return `${year}-${month}-${day} ${hour}:${min}:${sec}`
  } else if (format === 'date') {
    return `${month}-${day}(${weekList[week]})`
  }
}

export {
  timeStamp
}
